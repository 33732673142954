import logo from "./logo.svg";
import loading from "./loading.svg";
import menuClose from "./menuClose.svg";
import newChat from "./newChat.svg";
import message from "./message.svg";
import help from "./help.svg";
import settings from "./settings.svg";
import upgrade from "./upgrade.svg";
import plus from "./Plus.svg";
import logoShort from "./logoShort.svg";
import send from "./send.svg";
import chanakyaLogo from "./chanakyaLogo.svg";
import pro from "../svg/pro.svg";
import premiumLogo from "./premiumPer.svg";
import Tick from "./Tick.svg";
import downArrow from "./downArrow.svg";
import star from "./star.svg";
import infinite from "./infinite.svg";
import flagIcon from "./flag.svg";
import copyIcon from "./copyIcon.svg";
import Speaker from "./speaker.svg";
import muteSpeaker from "./speakerMute.svg";
import cross from "./cross.svg";
import alert from "./alert.svg";
import ChanakyaLogoSmall from "./ChanakayLogoSmall.svg";
import Paperclip from "./paperclip.svg";
import AudioSvg from "./audio.svg";
import imageSvg from "./image.svg";

export const AppSvg = {
  logo,
  loading,
  send,
  chanakyaLogo,
  pro,
  premiumLogo,
  Tick,
  downArrow,
  star,
  infinite,
  copyIcon,
  flagIcon,
  Speaker,
  muteSpeaker,
  cross,
  alert,
  ChanakyaLogoSmall,
  Paperclip,
  AudioSvg,
  imageSvg,
};

export const DrawerSvg = {
  menuClose,
  newChat,
  message,
  help,
  settings,
  upgrade,
  plus,
  logoShort,
};
