import React, { useState } from "react";
import { AppSvg } from "../../assets/svg";
import "./index.css";
import { useUser } from "../../redux/useStore";
import {
  PAYU_BASE_URL,
  MERCHANT_KEY,
  MERCHANT_SALT,
} from "../../config/envConfig";
import CryptoJS from "crypto-js";

interface PaymentData {
  key: string;
  txnid: string;
  amount: string;
  productinfo: string;
  firstname: string;
  email: string;
  phone: string;
  surl: string;
  furl: string;
  service_provider: string;
  hash?: string;
  udf1: string;
}

interface SubscriptionModalProps {
  onClose: () => void;
}

const SubscriptionModal: React.FC<SubscriptionModalProps> = ({ onClose }) => {
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null);
  const [planErrorMessage, setPlanErrorMessage] = useState("");
  const [takingPhoneInputModal, setIsTakingPhoneInputModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedPlanAmount, setSelectedPlanAmount] = useState<string>("");

  const user = useUser();

  const premiumFeatures = [
    { id: 1, title: "5x more messages." },
    { id: 2, title: "AI in 12+ India Languages" },
    { id: 3, title: "Real time web search" },
    { id: 4, title: "Advanced AI models." },
    { id: 5, title: "Enhanced chat memory" },
    { id: 6, title: "Full voice interaction (coming soon)" },
  ];

  const subscriptionPlans = [
    {
      id: "monthly",
      title: "Monthly",
      price: "₹99/month",
      amount: "99",
      savings: "No savings",
    },
    {
      id: "yearly",
      title: "Yearly",
      price: "₹5040/year",
      amount: "5040",
      savings: "Save ₹2400/year",
    },
    
  ];

  const handlePlanSelect = (planId: string) => {
    setSelectedPlanId(planId);
    setPlanErrorMessage("");
  };

  const generateHash = (data: PaymentData) => {
    const hashString = `${MERCHANT_KEY}|${data.txnid}|${data.amount}|${data.productinfo}|${data.firstname}|${data.email}|${data.udf1}||||||||||${MERCHANT_SALT}`;

    return CryptoJS.SHA512(hashString).toString();
  };

  const handleUpgradeClick = () => {
    if (!selectedPlanId) {
      setPlanErrorMessage("Please select a plan");
      return;
    }
    const selectedPlan = subscriptionPlans.find(
      (plan) => plan.id === selectedPlanId
    );
    if (selectedPlan) {
      setSelectedPlanId(selectedPlan.id);
      setSelectedPlanAmount(selectedPlan.amount);
      setIsTakingPhoneInputModal(true);
    }
  };

  const closeUpgradeModal = () => {
    setIsTakingPhoneInputModal(false);
  };

  const handleSubmit = () => {
    const data: PaymentData = {
      key: MERCHANT_KEY,
      txnid: `txn_${Date.now()}`,
      amount: selectedPlanAmount,
      productinfo: selectedPlanId || "",
      firstname: user?.firstName || "",
      email: user?.email || "",
      phone: phoneNumber,
      surl: "https://chanakya-payment.web.app/payment/success?gateway_redirect=true",
      furl: "https://chanakya-payment.web.app/payment/failure?gateway_redirect=true",
      service_provider: "payu_paisa",
      udf1: "PAYU-WEBHOOK-vX2lNp8GzJ6oQkT1Y3Rb9AsUwW4dFfH7no7bLM8dH1j6ZyTI79NrO10oiDgyJV_muoex72axSDQoAT5jE69zX6dqACSUwk_gvKTqRxXpiiXpMhgYGlucupvyHkPqVmLt0hOyCiZa5MeSr8xEjBKgD2uIc7LhWyQ1",
    };

    data.hash = generateHash(data);

    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value as string);
    });

   

    try {
      const tempForm = document.createElement("form");
      tempForm.method = "POST";
      tempForm.action = PAYU_BASE_URL;
      tempForm.style.display = "none";

      formData.forEach((value, key) => {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = value as string;
        tempForm.appendChild(input);
      });

      document.body.appendChild(tempForm);
      tempForm.submit();
      document.body.removeChild(tempForm);
    } catch (error) {
      console.error("Error submitting payment form:", error);
    }
  };

  return (
    <>
      <div className="premium-modal-overlay" onClick={onClose}>
        <div
          className="premium-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <button className="modal-close" onClick={onClose}>
            <img src={AppSvg.cross} alt="" />
          </button>
          <div className="modal-header">
            <h2>Chanakya.ai</h2>
            <img src={AppSvg.pro} alt="Profile" className="pro-premium" />
          </div>
          <div className="modal-description">
            <p>
              Unlock the most powerful AI assistance and be part of
              <br />
              <strong>India's AI Revolution</strong>.
            </p>
          </div>
          <div className="modal-content-wrapper">
            <div className="modal-image-container">
              <img
                src={AppSvg.premiumLogo}
                alt="Centered Content"
                className="modal-image"
              />
            </div>
            <div className="premium-features">
              <ul>
                {premiumFeatures.map((feature) => (
                  <li key={feature.id}>
                    <img src={AppSvg.Tick} alt="Tick" className="tick-icon" />
                    {feature.title}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="subscription-plans">
            <div className="subscription-plans-container">
              {subscriptionPlans.map((plan) => (
                <div
                  key={plan.id}
                  className={`planButton ${
                    selectedPlanId === plan.id ? "selected" : ""
                  }`}
                  onClick={() => handlePlanSelect(plan.id)}
                >
                  <div className="planContent">
                    <div className="planTextContainer">
                      <div className="planTitle">{plan.title}</div>
                      <div className="subscribeToday">{plan.price}</div>
                      <div className="planSavings">{plan.savings}</div>
                    </div>
                    {selectedPlanId === plan.id && (
                      <div className="checkmarkContainer">
                        <img
                          src={AppSvg.Tick}
                          alt="Checkmark"
                          className="checkmark"
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button
            className={`upgradeButton ${!selectedPlanId ? "disabled" : ""}`}
            onClick={handleUpgradeClick}
            disabled={!selectedPlanId}
          >
            Upgrade to Pro
          </button>
          {planErrorMessage && (
            <p className="error-message">{planErrorMessage}</p>
          )}
        </div>
      </div>

      {takingPhoneInputModal && (
        <div className="upgrade-modal-overlay" onClick={closeUpgradeModal}>
          <div
            className="upgrade-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <button className="upgrade-modal-close" onClick={closeUpgradeModal}>
              <img src={AppSvg.cross} alt="" />
            </button>
            <div className="upgrade-modal-container">
              <h2 className="upgrade-modal-title">Enter Phone Number</h2>
              <p className="upgrade-modal-description">
                Please enter your 10-digit Indian phone number to continue
                purchase
              </p>
              <div className="upgrade-modal-input-group">
                <input
                  type="text"
                  maxLength={10}
                  className="upgrade-modal-input"
                  placeholder="10-digit phone number"
                  onChange={(e) => {
                    const value = e.target.value;
                    setPhoneNumber(value);

                    if (!/^\d{0,10}$/.test(value)) {
                      setErrorMessage(
                        "Please enter a valid 10-digit phone number without special characters."
                      );
                      e.target.classList.add("error");
                    } else if (value.length === 10) {
                      setErrorMessage("");
                      e.target.classList.remove("error");
                    } else {
                      setErrorMessage("");
                      e.target.classList.remove("error");
                    }
                  }}
                />
                {errorMessage && (
                  <p className="error-message">{errorMessage}</p>
                )}{" "}
              </div>

              <div className="upgrade-modal-actions">
                <button
                  className={`upgrade-modal-submit ${
                    phoneNumber.trim().length === 0 ? "disabled" : ""
                  }`}
                  onClick={handleSubmit}
                  disabled={phoneNumber.trim().length === 0}
                >
                  Submit
                </button>
                <button
                  className="upgrade-modal-cancel"
                  onClick={closeUpgradeModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SubscriptionModal;
