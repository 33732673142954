import "./styles.css";
import { useState } from "react";
import { AppSvg } from "../../assets/svg";

import { useUser } from "../../redux/useStore";
import { useDispatch } from "react-redux";
import { setActiveUser } from "../../redux/userSlice";
import SubscriptionModal from "../PremiumSubscriptionModal";


export default function ChatHeader() {
  const [subscrptionModal, setIsSubscriptionModalOpen] = useState(false);
  const dispatch = useDispatch();
 
  const [isProModalOpen, setIsProModalOpen] = useState(false);

  const user = useUser();
  const userSubscription = user?.isPro;
  const isProSelected = user?.isProSelected;

  const handleHeaderClick = () => {
    if (user?.isPro) {
      setIsProModalOpen(true);
    } else {
      setIsSubscriptionModalOpen(true);
    }
  };


  const closeModal = () => {
    setIsSubscriptionModalOpen(false);
  };




  const handleProSelect = () => {
    dispatch(setActiveUser({ ...user, isProSelected: true }));
  };

  const handleBasicSelect = () => {
    dispatch(setActiveUser({ ...user, isProSelected: false }));
  };

  const renderSubscriptionButtons = () => {
    return (
      <>
        <button
          className="header-container"
          onClick={() => userSubscription && handleProSelect()}
        >
          <div className="row-center">
            <img
              src={AppSvg.star}
              alt=""
              style={{ width: "24px", height: "24px", marginRight: "10px" }}
            />
            <div className="title-with-image">
              <p
                className={`modal-title ${
                  userSubscription && isProSelected ? "selected-title" : ""
                }`}
              >
                Chanakya Pro
              </p>
              <p className="modal-subheader">Our smartest model</p>
            </div>
          </div>
          {isProSelected && (
            <button className="active-button">
              <p className="active-button-text"><strong>Active Plan </strong></p>
            </button>
          )}
        </button>

        <button className="header-container" onClick={handleBasicSelect}>
          <div className="row-center">
            <img
              src={AppSvg.infinite}
              alt=""
              style={{ width: "24px", height: "24px", marginRight: "10px" }}
            />
            <div className="title-with-image">
              <p
                className={`modal-title ${
                  !isProSelected || !userSubscription ? "selected-title" : ""
                }`}
              >
                Chanakya
              </p>
              <p className="modal-subheader">Great for everyday tasks</p>
            </div>
          </div>
          {(!isProSelected || !userSubscription) && (
            <img
              src={AppSvg.Tick}
              alt=""
              style={{ width: "24px", height: "24px" }}
            />
          )}
        </button>
      </>
    );
  };

  return (
    <>
      <div>
        <div className="header" onClick={handleHeaderClick}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={AppSvg.chanakyaLogo}
              alt=""
              style={{ width: "120px", margin: "0 5px" }} 
            />
            {user?.isPro && isProSelected && (
              <img src={AppSvg.pro} alt="Profile" style={{ margin: "0 2px" }} />
            )}
            <img
              src={AppSvg.downArrow}
              alt="Down Arrow"
              style={{
                width: "24px",
                height: "24px",
                margin: "0 2px",
              }}
            />
          </div>
        </div>

        {subscrptionModal && !user?.isPro && (
          <SubscriptionModal
            onClose={closeModal}
          />
        )}
      </div>
      <div>
       
        {isProModalOpen && (
          <div
            className="premiumSelectionOverlay"
            onClick={() => setIsProModalOpen(false)}
          >
            <div className="premiumSelectionContent">
              <div className="pro-modal-container">
                {renderSubscriptionButtons()}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}