import { createSlice } from "@reduxjs/toolkit";
import { LocalUser } from "../utils/interface/localUser";

const initialState = {
  user: null as LocalUser | null,
  isLoggedIn: false as boolean,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setActiveUser: (state, action) => {
      state.user = action.payload as LocalUser;
      state.isLoggedIn = true;
    },
    setLogoutUser: (state) => {
      state.user = null;
      state.isLoggedIn = false;
    },
  },
});

export const { setActiveUser, setLogoutUser } = userSlice.actions;

export const selectUser = (state: any) => state.user.user;
export const selectIsLoggedIn = (state: any) => state.user.isLoggedIn;

export default userSlice.reducer;
