import  { useState } from 'react';
import { AppSvg, DrawerSvg } from "../../assets/svg";
import { useUser } from "../../redux/useStore";
import "./styles.css";
import { useDispatch } from "react-redux";
import { setLogoutUser } from "../../redux/userSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { useChatHistory } from "../../hooks/chat";
import { useNavigate, useParams } from "react-router-dom";
import { DrawerIcon } from "../../assets/images";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";

interface DrawerProps {
  onMenuClick: () => void;
  mode: "closed" | "opened";
}

export default function Drawer(props: DrawerProps) {
  const user = useUser();
  const dispatch = useDispatch();
  const auth = useAuth0();
  const params = useParams();
  const navigate = useNavigate();
  const [showAllChats, setShowAllChats] = useState(false);

  const {
    data: chatHistory,
    isLoading: isHistoryLoading,
  } = useChatHistory();

  const drawerOptions = [
    { id: 1, icon: DrawerSvg.help, text: "Help", onClick: () => onHelpClick() },
    {
      id: 2,
      icon: DrawerSvg.settings,
      text: "Settings",
      onClick: () => onSettingClick(),
    },
    {
      id: 3,
      icon: DrawerSvg.menuClose,
      text: "Logout",
      onClick: () => {
        onLogoutClick();
      },
    },
    {
      id: 4,
      icon: user?.profilePhoto !== "" ? user?.profilePhoto : DrawerIcon.user,
      text: user?.firstName,
      onClick: () => {},
    },
  ];

  const drawerIsClosed = props.mode === "closed";

  const onMenuClick = () => {
    props.onMenuClick();
  };


  
  const onNewChatClick = () => {
    console.log("New chat clicked");
    navigate("/");
  };

  const toggleShowAllChats = () => {
    setShowAllChats(!showAllChats);
  };

  const onChatClick = (chat: any) => {
    document.title = `${chat.title} | Chanakya Ai ` || "Chat";
    navigate(`/chat/${chat.id}`);
  };

  const onHelpClick = () => {
    let url = "https://neurobridge.atlassian.net/servicedesk/customer/portals";
    window.open(url, "_blank");
  };

  const onSettingClick = () => {
    navigate("/settings/profile");
  };

  const onLogoutClick = async () => {
    const logout = window.confirm("Are you sure you want to logout?");
    if (!logout) return;

    await auth
      .logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      })
      .then(() => {
        dispatch(setLogoutUser());
        localStorage.clear();
      });
  };

  const visibleChats = showAllChats ? chatHistory : chatHistory.slice(0, 5);

  return (
    <div className={`drawer ${drawerIsClosed ? "closed" : "opened"}`}>
      {drawerIsClosed === false ? (
        <div className="drawer-header">
          <img src={AppSvg.chanakyaLogo} alt="logo" style={{width: "183px", height: "25px"}}/>
          <img
            src={DrawerSvg.menuClose}
            className="menu-close"
            alt="menu-close"
            onClick={onMenuClick}
            style={{ width: "25px", height: "25px" }}
          />
        </div>
      ) : (
        <div className="drawer-header closed">
          <img
            src={AppSvg.ChanakyaLogoSmall}
            alt="logo"
            className='drawer-logo'
            onClick={onMenuClick}
          />
          <Link to="/" reloadDocument>
            <div
              className="new-chat-round"
              onClick={onNewChatClick}
              data-tooltip-id="new-chat-tooltip"
              data-tooltip-content="Start a New Chat"
            >
              <img
                src={DrawerSvg.plus}
                alt="plus"
                style={{ width: "25px", height: "25px" }}
                className="hover-animation"
              />
            </div>
            <Tooltip id="new-chat-tooltip" />
          </Link>
        </div>
      )}

      <div
        className={drawerIsClosed ? "drawer-body closed" : "drawer-body opened"}
      >
        <div className={`chat-list ${drawerIsClosed ? "closed" : "opened"}`}>
          <Link to="/" reloadDocument>
            <img
              src={DrawerSvg.newChat}
              className="new-chat-text hover-animation"
              alt="new chat"
              onClick={onNewChatClick}
            />
          </Link>
          <h4>Recent Chats</h4>
          <div className="chat-item-holder">
            {!isHistoryLoading ? (
              chatHistory.length > 0 ? (
                <>
                  {visibleChats.map((item: any, index: number) => {
                    const isSelected = item.id === params.id;
                    return (
                      <div
                        key={index}
                        className={`chat-item ${isSelected ? 'selected' : ''}`}
                        onClick={() => onChatClick(item)}
                      >
                        <img src={DrawerSvg.message} alt="avatar" className="hover-animation" />
                        <span className="chat-title">
                          {item.title}
                        </span>
                      </div>
                    );
                  })}
                  {chatHistory.length > 5 && (
                    <div className="show-more-less" onClick={toggleShowAllChats}>
                      {showAllChats ? 'Show Less' : 'Show More'}
                    </div>
                  )}
                </>
              ) : (
                <div className="chat-item">
                  <img src={DrawerSvg.message} alt="avatar" className="hover-animation" />
                  <span>Let's get started!</span>
                </div>
              )
            ) : (
              <div className="chat-item">
                <img
                  src={AppSvg.loading}
                  alt="loading"
                  style={{ width: "20px", height: "20px" }}
                  className="hover-animation"
                />
                <span>Loading chats...</span>
              </div>
            )}
          </div>
        </div>

        {drawerIsClosed && <div className="closed-placeholder"></div>}

        <div className="option-holder">
          {drawerOptions.map((option, index) => (
            <div
              className={
                drawerIsClosed ? "option-item closed" : "option-item opened"
              }
              key={index}
              onClick={option.onClick}
              data-tooltip-id={`drawer-option-tooltip-${option.id}`}
              data-tooltip-content={option.text}
            >
              <div className="item">
                <img
                  src={option.icon}
                  alt={option.text}
                  className="drawer-option-icon hover-animation"
                  style={{ width: "25px", height: "25px" }}
                />
                {drawerIsClosed === false && <span>{option.text}</span>}
              </div>
              {drawerIsClosed === false && <div className="dot" />}
              {drawerIsClosed && <Tooltip id={`drawer-option-tooltip-${option.id}`} />}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

